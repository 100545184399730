import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showSubscriptionPopup, getSubscriptions, deleteSubscription } from '../../store/subscriptionSlice';
import Icon from '../common/Icon';
import SubscriptionPopup from './SubscriptionPopup';
import InlinePopup from '../common/InlinePopup';

export default function Subscriptions() {

    let [showingDelete, setShowDelete] = useState(false);
    let [selected, setSelected] = useState(null);
    let list = useSelector( state => state.subscription.list );

    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSubscriptions());
    }, [])

    const createNewSubscription = (e) => {
        e.preventDefault();
        dispatch(showSubscriptionPopup());
    };

    const editSubscriptionClick = (subscription) => (e) => {
        e.preventDefault();
        dispatch(showSubscriptionPopup(subscription));
    }

    const deleteSubscriptionClick = (subscription) => (e) => {
        e.preventDefault();
        setSelected(subscription);
        setShowDelete(true);
    }

    const hideDeletePopup = (e) => {
        e.preventDefault();
        setShowDelete(false);
    };

    const renderRows = () => {
        if (list.length == 0) {
            return (
                <tr key="0">
                    <td colSpan="6">No data</td>
                </tr>
            );
        }
        return list.map((subscription, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{ subscription.device_id }</td>
                    <td>{ subscription.timer }</td>
                    <td>{ subscription.platform }</td>
                    <td>{ subscription.status }</td>
                    
                    <td className="table-actions">
                        <a href="/#" onClick={ editSubscriptionClick(subscription) }>
                            <Icon icon="edit-2" />
                        </a>

                        <a href="/#" onClick={ deleteSubscriptionClick(subscription) }>
                            <Icon icon="trash" />
                        </a>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">Subscriptions</h5>

                <div className="card-actions">
                    <a href="/#" className="btn btn-primary" onClick={createNewSubscription}>
                        <Icon size="16" icon="plus"/>
                        Create New Subscription
                    </a>
                </div>
            </div>
            
            <table className="table">
                <thead>
                    <th>ID</th>
                    <th className="sortable sort-asc">Device ID</th>
                    <th>Timer</th>
                    <th>Platform</th>
                    <th>Status</th>
                    <th>Actions</th>
                </thead>
                <tbody>
                    { renderRows() }
                </tbody>
            </table>

            <SubscriptionPopup />

            <InlinePopup 
                isShowing={showingDelete} 
                hide={hideDeletePopup} 
                title="Delete Subscription" 
                message="Are you sure you want to delete this subscription?" 
                action={deleteSubscription(selected)} />
        </div>
    );
}