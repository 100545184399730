import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showAppPopup, getApps, deleteApp } from '../../store/appSlice';
import Icon from '../common/Icon';
import AppPopup from './AppPopup';
import InlinePopup from '../common/InlinePopup';

export default function Apps() {

    let [showingDelete, setShowDelete] = useState(false);
    let [selected, setSelected] = useState(null);
    let list = useSelector( state => state.app.list );

    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getApps());
    }, [])

    const createNewApp = (e) => {
        e.preventDefault();
        dispatch(showAppPopup());
    };

    const editAppClick = (app) => (e) => {
        e.preventDefault();
        dispatch(showAppPopup(app));
    }

    const deleteAppClick = (app) => (e) => {
        e.preventDefault();
        setSelected(app);
        setShowDelete(true);
    }

    const hideDeletePopup = (e) => {
        e.preventDefault();
        setShowDelete(false);
    };

    const renderRows = () => {
        if (list.length == 0) {
            return (
                <tr key="0">
                    <td colSpan="4">No data</td>
                </tr>
            );
        }
        return list.map((app, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{ app.name }</td>
                    <td>{ app.description }</td>
                    <td className="table-actions">
                        <a href="/#" onClick={ editAppClick(app) }>
                            <Icon icon="edit-2" />
                        </a>

                        <a href="/#" onClick={ deleteAppClick(app) }>
                            <Icon icon="trash" />
                        </a>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">Apps</h5>

                <div className="card-actions">
                    <a href="/#" className="btn btn-primary" onClick={createNewApp}>
                        <Icon size="16" icon="plus"/>
                        Create New App
                    </a>
                </div>
            </div>
            
            <table className="table">
                <thead>
                    <th>ID</th>
                    <th className="sortable sort-asc">App Name</th>
                    <th>Description</th>
                    <th>Actions</th>
                </thead>
                <tbody>
                    { renderRows() }
                </tbody>
            </table>

            <AppPopup />

            <InlinePopup 
                isShowing={showingDelete} 
                hide={hideDeletePopup} 
                title="Delete App" 
                message="Are you sure you want to delete this app?" 
                action={deleteApp(selected)} />
        </div>
    );
}