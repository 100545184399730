import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closePopup, createApp, updateApp } from '../../store/appSlice';
import Icon from "../common/Icon";

const AppPopup = () => {

    let loading = useSelector(state => state.app.loading);
    let popup = useSelector(state => state.app.popup);
    let form = useSelector(state => state.app.form);

    const initialState = {
        description: '',
        firebaseApiKey: '',
        name: '',
        token: '',
        title: '',
        body: '',
        ...form
    };

    let [{
        description,
        firebaseApiKey,
        name,
        token,
        title,
        body,
    }, setState] = useState(initialState);

    useEffect(() => { setState(initialState)}, [form]);

    const dispatch = useDispatch();

    const close = (e) => {
        e.preventDefault();
        dispatch(closePopup());
        // clear();
    };

    const create = (e) => {
        // Frontend validation
        // Should do with Formik

        dispatch(createApp({
            description: description,
            firebaseApiKey: firebaseApiKey,
            name: name,
            token: token,
            title: title,
            body: body,
        }));
    }

    const update = (e) => {
        e.preventDefault();
        console.log(form.id);
        dispatch(updateApp({
            id: form.id,
            description: description,
            firebaseApiKey: firebaseApiKey,
            name: name,
            token: token,
            title: title,
            body: body,
        }));
    }

    const change = (e) => {
        let {name, value} = e.target;
        setState(prevState => ({...prevState, [name]: value}))
    }

    return (
        <div className={ "popup" + (popup ? " popup-show" : "")}>
            <div className="popup-overlay"></div>

            <div className="popup-wrapper">
                <div className="popup-content">
                    <div className="card">
                        <div className="card-header">
                            { form ?
                                <h5 className="card-title">Edit App</h5>
                                : 
                                <h5 className="card-title">Create New App</h5>
                            }
                            <div>
                                <a href="/#" onClick={close}>
                                    <Icon icon="x"/>
                                </a>
                                
                            </div>
                        </div>

                        <div className="card-body">
                            <form >
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" className="form-control" value={ name } name="name" onChange={change} />
                                </div>
                                <div className="form-group">
                                    <label>Message Title</label>
                                    <input type="text" className="form-control" value={ title } name="title" onChange={change} />
                                </div>
                                <div className="form-group">
                                    <label>Message Body</label>
                                    <input type="text" className="form-control" value={ body } name="body" onChange={change} />
                                </div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <input type="text" className="form-control" value={ description } name="description" onChange={change} />
                                </div>
                                <div className="form-group">
                                    <label>Firebase API Key</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        name="firebaseApiKey"
                                        rows={10}
                                        readOnly={ form }
                                        onChange={change}
                                        value={firebaseApiKey}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Token</label>
                                    <input type="text" className="form-control" value={ token } name="token" onChange={change} />
                                </div>
                            </form>

                            <div className="actions">
                                { form ? 
                                    <button onClick={update} className="btn btn-primary">Update</button>
                                    :
                                    <button onClick={create} className="btn btn-primary">Create</button>
                                }   
                                <div className="fill"></div>

                                { loading && 
                                    <div className="loading">
                                        <div className="threedots">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppPopup;