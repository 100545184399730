import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: {
        loading: false,
        error: null,
        list: [],
        form: null,
        popup: false,
    },
    reducers: {
        subscriptionsRequested: (state, action) => {
            state.loading = true;
        },
        subscriptionsReceived: (state, action) => {
            state.loading = false;
            state.list = action.payload.data;
        },
        subscriptionsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        subscriptionRequested: (state, action) => {
            state.loading = true;
        },
        subscriptionCreated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.list.push(action.payload);
            state.form = null;
        },
        subscriptionUpdated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.form = null;
            const index = state.list.findIndex( subscription => subscription.id === action.payload.id );
            state.list[index] = action.payload;
        },
        subscriptionDeleted: (state, action) => {
            state.loading = false;
            state.list = state.list.filter( subscription => subscription.id !== action.payload.id );
        },
        subscriptionError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        showSubscriptionPopup: (state, action) => {
            state.popup = true;
            state.form = action.payload;
        },
        closePopup: (state, action) => {
            state.popup = false;
            state.form = null;
        }
    }
});

const {
    subscriptionsRequested,
    subscriptionsReceived,
    subscriptionsError,
    subscriptionRequested,
    subscriptionError,
    subscriptionCreated,
    subscriptionUpdated,
    subscriptionDeleted,
    showSubscriptionPopup,
    closePopup,
} = subscriptionSlice.actions;

const getSubscriptions = () => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/adm/subscriptions',
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: subscriptionsRequested.type,
                onSuccess: subscriptionsReceived.type,
                onError: subscriptionsError.type
            }
        )
    );
}

const createSubscription = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/adm/subscriptions',
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: subscriptionRequested.type,
                onSuccess: subscriptionCreated.type,
                onError: subscriptionError.type
            }
        )
    );
}

const updateSubscription = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/adm/subscriptions/' + data.id,
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: subscriptionRequested.type,
                onSuccess: subscriptionUpdated.type,
                onError: subscriptionError.type
            }
        )
    );
}

const deleteSubscription = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/adm/subscriptions/' + data.id,
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: subscriptionRequested.type,
                onSuccess: subscriptionDeleted.type,
                onError: subscriptionError.type
            }
        )
    );
}

export { 
    getSubscriptions, 
    createSubscription, 
    updateSubscription, 
    deleteSubscription, 
    showSubscriptionPopup, 
    closePopup 
};

export default subscriptionSlice.reducer;