import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closePopup, createSubscription, updateSubscription } from '../../store/subscriptionSlice';
import Icon from "../common/Icon";

const SubscriptionPopup = () => {

    let loading = useSelector(state => state.subscription.loading);
    let popup = useSelector(state => state.subscription.popup);
    let form = useSelector(state => state.subscription.form);

    const initialState = {
        condition: '',
        device_id: '',
        device_token: '',
        platform: '',
        status: '',
        timer: '',
        ...form
    };

    let [{
        condition,
        device_id,
        device_token,
        platform,
        status,
        timer,
    }, setState] = useState(initialState);

    useEffect(() => { setState(initialState)}, [form]);

    const dispatch = useDispatch();

    const close = (e) => {
        e.preventDefault();
        dispatch(closePopup());
        // clear();
    };

    const create = (e) => {
        // Frontend validation
        // Should do with Formik

        dispatch(createSubscription({
            condition: condition,
            device_id: device_id,
            device_token: device_token,
            platform: platform,
            status: status,
            timer: timer,
        }));
    }

    const update = (e) => {
        e.preventDefault();
        dispatch(updateSubscription({
            id: form.id,
            condition: condition,
            device_id: device_id,
            device_token: device_token,
            platform: platform,
            status: status,
            timer: timer,
        }));
    }

    const change = (e) => {
        let {name, value} = e.target;
        setState(prevState => ({...prevState, [name]: value}))
    }

    return (
        <div className={ "popup" + (popup ? " popup-show" : "")}>
            <div className="popup-overlay"></div>

            <div className="popup-wrapper">
                <div className="popup-content">
                    <div className="card">
                        <div className="card-header">
                            { form ?
                                <h5 className="card-title">Edit Subscription</h5>
                                : 
                                <h5 className="card-title">Create New Subscription</h5>
                            }
                            <div>
                                <a href="/#" onClick={close}>
                                    <Icon icon="x"/>
                                </a>
                                
                            </div>
                        </div>

                        <div className="card-body">
                            <form >
                                <div className="form-group">
                                    <label>Condition</label>
                                    <input type="text" className="form-control" value={ condition } name="condition" readOnly={ form } onChange={change} />
                                </div>
                                <div className="form-group">
                                    <label>Device_id</label>
                                    <input type="text" className="form-control" value={ device_id } name="device_id" readOnly={ form } onChange={change} />
                                </div>
                                <div className="form-group">
                                    <label>Device_token</label>
                                    <input type="text" className="form-control" value={ device_token } name="device_token" readOnly={ form } onChange={change} />
                                </div>
                                <div className="form-group">
                                    <label>Platform</label>
                                    <input type="text" className="form-control" value={ platform } name="platform" readOnly={ form } onChange={change} />
                                </div>
                                <div className="form-group">
                                    <label>Status</label>
                                    <input type="text" className="form-control" value={ status } name="status" readOnly={ form } onChange={change} />
                                </div>
                                <div className="form-group">
                                    <label>Timer</label>
                                    <input type="text" className="form-control" value={ timer } name="timer" readOnly={ form } onChange={change} />
                                </div>
                            </form>

                            <div className="actions">
                                { form ? 
                                    <button onClick={update} className="btn btn-primary">Update</button>
                                    :
                                    <button onClick={create} className="btn btn-primary">Create</button>
                                }   
                                <div className="fill"></div>

                                { loading && 
                                    <div className="loading">
                                        <div className="threedots">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionPopup;