import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        loading: false,
        error: null,
        list: [],
        form: null,
        popup: false,
    },
    reducers: {
        appsRequested: (state, action) => {
            state.loading = true;
        },
        appsReceived: (state, action) => {
            state.loading = false;
            state.list = action.payload.data;
        },
        appsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        appRequested: (state, action) => {
            state.loading = true;
        },
        appCreated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.list.push(action.payload);
            state.form = null;
        },
        appUpdated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.form = null;
            const index = state.list.findIndex( app => app.id === action.payload.id );
            state.list[index] = action.payload;
        },
        appDeleted: (state, action) => {
            state.loading = false;
            state.list = state.list.filter( app => app.id !== action.payload.id );
        },
        appError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        showAppPopup: (state, action) => {
            state.popup = true;
            state.form = action.payload;
        },
        closePopup: (state, action) => {
            state.popup = false;
            state.form = null;
        }
    }
});

const {
    appsRequested,
    appsReceived,
    appsError,
    appRequested,
    appError,
    appCreated,
    appUpdated,
    appDeleted,
    showAppPopup,
    closePopup,
} = appSlice.actions;

const getApps = () => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/adm/apps',
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: appsRequested.type,
                onSuccess: appsReceived.type,
                onError: appsError.type
            }
        )
    );
}

const createApp = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/adm/apps',
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: appRequested.type,
                onSuccess: appCreated.type,
                onError: appError.type
            }
        )
    );
}

const updateApp = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/adm/apps/' + data.id,
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data,
                onStart: appRequested.type,
                onSuccess: appUpdated.type,
                onError: appError.type
            }
        )
    );
}

const deleteApp = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/adm/apps/' + data.id,
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                onStart: appRequested.type,
                onSuccess: appDeleted.type,
                onError: appError.type
            }
        )
    );
}

export { 
    getApps, 
    createApp, 
    updateApp, 
    deleteApp, 
    showAppPopup, 
    closePopup 
};

export default appSlice.reducer;